import React, { useCallback, useEffect, useReducer, useState } from 'react';

// utils
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import {
  checkToAddOptout,
  filterValidValuesFromButton,
  getCTAButtons,
} from 'components/commonComponents/templates/Buttons/utils';

// constants
import { CATEGORIES_OPTIONS, LANGUAGE_OPTIONS } from '../../constant';

// hooks
import useToggle from 'hooks/useToggle';

// reducers
import {
  INITIAL_STATE,
  templateReducer,
} from 'hooks/templates/templateReducers';

// helper
import { capitalizeFirstLetter } from 'helper/capitalizeFirstLetter';
import { getLanguageCode, getLanguageName } from 'helper/setLanguageCode';
import { isValidHttpsUrl } from 'helper/checkUrls';
import { removeDoubleStyles, getDataFromTemplate } from 'helper/templates';
import { bodyTextLimit, getVariablesCount } from './index';

// Api
import { UpdateTemplate, createAndUpdateTemplate } from '../../api/Api';

// redux
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../test/jest-redux-hooks';
import {
  resetTemplateDetails,
  update,
} from '../../../../reduxToolkit/templateSlice';
import { updateToggleToast } from '../../../../reduxToolkit/appSlice';

// components
import TemplateHeader from './TemplateHeader';
import TemplateBody from './TemplateBody';
import TemplateName from './TemplateName';
import TemplateFooter from './TemplateFooter';
import TemplateButtons from './TemplateButtons';
import MessagePreview from './MessagePreview';
import Loader from 'components/commonComponents/Loader/Index';
import Select from 'components/commonComponents/select/index';
import Alert from 'components/commonComponents/modals/Alert';
import TemplatePreview from '../../../../components/commonComponents/templates/TemplatePreview';
import { checkMultipleNewLines } from '../../../inbox/helper';

let templateName = '';

export default function TemplateDetails() {
  const navigate = useNavigate();
  const { template } = useParams();

  const dispatch = useAspDispatch();
  const templateDetails = useAspSelector((state) => state?.Template);
  const [footerText, setFooterText] = useState('');

  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );
  const {
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
  } = templateData;

  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);

  const [showOnUnloadPopup, setshowOnUnloadPopup] = useToggle(false);

  const [templateNameError, setTemplateNameError] = useToggle(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [loading, setLoading] = useToggle(false);
  const [isDiscard, setIsDiscard] = useToggle(false);

  // editor
  const [showEmojiPicker, setShowEmojiPicker] = useToggle(false);
  const checkOptOutButton = checkToAddOptout(buttons);

  useEffect(() => {
    if (!showOnUnloadPopup) return;
    else {
      const handleonBeforeUnload = (e) => {
        e.preventDefault();
        return (e.returnValue = '');
      };

      window.addEventListener('beforeunload', handleonBeforeUnload, {
        capture: true,
      });

      return () => {
        window.removeEventListener('beforeunload', handleonBeforeUnload, {
          capture: true,
        });
      };
    }
  }, [showOnUnloadPopup]);

  useEffect(() => {
    if (template === 'create-template' && templateDetails?.language?.[0]) {
      const language = templateDetails?.language?.[0]
        ? Object?.keys(templateDetails?.language?.[0])
        : '';
      const res = LANGUAGE_OPTIONS?.filter((l) => l.value === language?.[0]);
      setSelectedLanguage(res?.[0]);
      // setSelectedLanguage({
      //   label: language?.[0],
      //   value: language?.[0],
      // });
      // if (res?.length > 0) setSelectedLanguage(res?.[0]);
      // else setSelectedLanguage(LANGUAGE_OPTIONS?.[0]);
    }

    if (
      template === 'duplicate-template' &&
      templateDetails?.details?.name?.length > 0
    ) {
      setTemplateNameError(true);
    }

    if (['edit-template', 'duplicate-template']?.includes(template)) {
      const res = LANGUAGE_OPTIONS?.filter(
        (l) => l.value === getLanguageName(templateDetails?.details?.language)
      );
      if (res?.length > 0) setSelectedLanguage(res?.[0]);
      else setSelectedLanguage(LANGUAGE_OPTIONS?.[0]);
      // setSelectedLanguage({
      //   label: getLanguageName(templateDetails?.details?.language),
      //   value: getLanguageName(templateDetails?.details?.language),
      // });
    }

    const categorys = capitalizeFirstLetter(templateDetails?.category)
      ? {
          label: capitalizeFirstLetter(templateDetails?.category),
          value: templateDetails?.category,
        }
      : null;

    setSelectedCategory(categorys);
  }, []);

  useEffect(() => {
    if (['edit-template', 'duplicate-template']?.includes(template)) {
      templateName = templateDetails?.details?.name;
    }
  }, [templateDetails]);

  useEffect(() => {
    if (['edit-template', 'duplicate-template']?.includes(template)) {
      templateDispatch({
        type: 'update_template',
        data: templateDetails?.details,
      });
    }
  }, []);

  useEffect(() => {
    checkValueHasChanged();
  }, [
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
  ]);

  const checkValueHasChanged = () => {
    if (template === 'create-template') {
      const data =
        JSON.stringify(INITIAL_STATE) === JSON.stringify(templateData);
      setshowOnUnloadPopup(!data);
    }

    if (['edit-template', 'duplicate-template'].includes(template)) {
      const datas = getDataFromTemplate(templateDetails?.details);
      const data = JSON.stringify(datas) === JSON.stringify(templateData);
      setshowOnUnloadPopup(!data);
    }
  };

  // handle buttons
  const updateButtons = ({ updatevalue, btnObj, key, value, id }) => {
    if (btnObj?.format === 'OPT_OUT') {
      templateDispatch({
        type: 'footer',
        text: 'Not interested? Tap Stop promotions',
      });
      setFooterText('Not interested? Tap Stop promotions');
    }
    if (updatevalue === 'remove') {
      const removed = buttons?.filter((b) => b.id === id);
      if (removed?.length > 0) {
        if (removed[0]?.format === 'OPT_OUT') {
          templateDispatch({
            type: 'footer',
            text: '',
          });
          setFooterText('');
        }
      }
    }
    if (!loading) {
      templateDispatch({
        type: 'buttons',
        updateType: updatevalue,
        btnObj: btnObj
          ? { id: id, ...btnObj }
          : {
              id: id,
              key: key,
              value: value,
            },
      });
    }
  };

  //**  valiadtion for submit the template **//
  // submit actions
  const buttonValidatations = useCallback(
    (type) => {
      return ctaButtons?.filter((b) => {
        if (type === 'URL')
          return (
            b?.text &&
            b?.url &&
            isValidHttpsUrl(b?.url) &&
            b?.text?.length <= 25 &&
            b?.url?.length <= 2000
          );
        if (type === 'PHONE_NUMBER')
          return (
            b?.text &&
            b?.text.length <= 25 &&
            b?.phone_number &&
            b?.phone_number.slice(3).length <= 20
          );
        if (type === 'COPY_CODE')
          return (
            b?.example &&
            b?.example?.[0] !== '' &&
            b?.example?.[0]?.length <= 15
          );
        if (format === 'CUSTOM') return b?.text !== '' && b?.text?.length <= 25;
        if (format === 'OPT-OUT')
          return b?.text !== '' && b?.text?.length <= 25;
      });
    },
    [ctaButtons]
  );

  const customButtonValidatations = useCallback(
    (format) => {
      return customButtons?.filter((b) => {
        if (b?.format === format) return b.text !== '' && b.text?.length < 25;
      });
    },
    [customButtons]
  );

  // check the specific type of buttons length in button array
  const chekButtonsLength = useCallback(
    (type) => buttons?.filter((b) => b.type === type),
    [buttons]
  );

  const checkOptoutisExistinCustom = useCallback(() => {
    let optoutFilter = [];
    customButtons?.map((b) => {
      if (b.format === 'CUSTOM' && b.text === 'Stop promotions')
        optoutFilter.push(b);
    });
    return optoutFilter?.length === 0 ? true : false;
  }, [customButtons]);

  // check is allow to submit the template
  const checkAllowToSubmit = useCallback(() => {
    // header validation
    const isHeader = header
      ? (header?.format === 'TEXT' && header?.text !== '' && !headerVariable) ||
        (header?.format === 'TEXT' &&
          header?.text !== '' &&
          headerVariable &&
          headerVariable?.value !== '') ||
        (['IMAGE', 'VIDEO', 'DOCUMENT'].includes(header?.format) &&
          header?.example?.header_handle &&
          header?.example?.header_handle?.[0] !== '')
      : true;
    // body validation
    const isBodyVariable = bodyVariables?.filter((v) => v.value === '');

    const checkLine = checkMultipleNewLines(body?.text);
    const newLineLength = checkLine?.length > 0 ? false : true;

    const isBody =
      getVariablesCount(body?.text)?.length !== 0 &&
      getVariablesCount(body?.text)?.length <= bodyTextLimit &&
      isBodyVariable?.length === 0
        ? true
        : getVariablesCount(body?.text)?.length !== 0 &&
          getVariablesCount(body?.text)?.length <= bodyTextLimit &&
          isBodyVariable?.length > 0
        ? false
        : getVariablesCount(body?.text)?.length !== 0 &&
          getVariablesCount(body?.text)?.length <= bodyTextLimit &&
          newLineLength
        ? false
        : getVariablesCount(body?.text)?.length !== 0 &&
          getVariablesCount(body?.text)?.length <= bodyTextLimit
        ? true
        : false;

    // buttons validations
    const urlButtons =
      chekButtonsLength('URL')?.length > 0
        ? buttonValidatations('URL')?.length ===
          getCTAButtons(ctaButtons, 'URL')?.length
          ? true
          : false
        : true;

    const phonenumberButtons =
      chekButtonsLength('PHONE_NUMBER')?.length > 0
        ? buttonValidatations('PHONE_NUMBER')?.length ===
          getCTAButtons(ctaButtons, 'PHONE_NUMBER')?.length
          ? true
          : false
        : true;

    const copyButtons =
      chekButtonsLength('COPY_CODE')?.length > 0
        ? buttonValidatations('COPY_CODE')?.length ===
          getCTAButtons(ctaButtons, 'COPY_CODE')?.length
          ? true
          : false
        : true;

    const isCustom =
      chekButtonsLength('QUICK_REPLY')?.length > 0
        ? customButtonValidatations('CUSTOM')?.length ===
          getCTAButtons(customButtons, 'CUSTOM')?.length
          ? true
          : false
        : true;

    const isButtons =
      urlButtons && phonenumberButtons && copyButtons && isCustom
        ? true
        : false;

    const language = templateDetails?.language?.[0]
      ? Object?.keys(templateDetails?.language?.[0])
      : '';
    const res = LANGUAGE_OPTIONS?.filter(
      (l) =>
        l.label === selectedLanguage?.label &&
        l.value === selectedLanguage?.value
    );

    return (
      templateDetails?.name &&
      !templateNameError &&
      isHeader &&
      isBody &&
      isButtons &&
      checkOptoutisExistinCustom() &&
      newLineLength &&
      res?.length > 0
    );
  }, [
    templateNameError,
    templateName,
    header,
    body,
    footer,
    buttons,
    ctaButtons,
    customButtons,
    checkOptoutisExistinCustom(),
    bodyVariables,
  ]);
  //** validations ends here  **//

  // cancel the template
  const discard = () => {
    dispatch(resetTemplateDetails());
    templateDispatch({
      type: 'reset',
    });
    navigate('/user/channels/whatsapp/message-templates');
  };

  //** make the api call for create and update the template *//
  const createAndUpdateTemplateData = (draft = 'false') => {
    setLoading(true);

    const languages = getLanguageCode(selectedLanguage?.value);

    let component = [];

    if (header) {
      component.push(header);
    }

    if (footer) {
      component.push(footer);
    }

    if (checkOptOutButton && footer && footer?.text > 0) {
      component.push(footer);
    }

    if (bodyVariables?.length > 0) {
      component.push({
        type: 'BODY',
        text: body?.text,
        example: {
          body_text: [
            bodyVariables?.map((b) => {
              return b.value;
            }),
          ],
        },
      });
    } else {
      component.push({
        type: 'BODY',
        text: removeDoubleStyles(body?.text),
      });
    }

    if (buttons?.length > 0) {
      const cta = filterValidValuesFromButton(ctaButtons);
      const custom = filterValidValuesFromButton(customButtons);
      component.push({
        type: 'BUTTONS',
        buttons:
          buttonsOrder === 'ctaButtons'
            ? [...cta, ...custom]
            : [...custom, ...cta],
      });
    }

    const data = {
      category: selectedCategory?.value,
      name: templateDetails?.name,
      language: languages,
      brand_id: currentBrand?.brand_id,
      components: component,
      save_as_draft: draft,
    };

    if (
      ['create-template', 'duplicate-template']?.includes(template) ||
      (['edit-template']?.includes(template) &&
        templateDetails?.details?.status === 'DRAFT')
    ) {
      createAndUpdateTemplate(data)
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: draft
                    ? 'Template saved as draft'
                    : 'Template created successfully',
                  status: 'Success',
                  duration: '',
                },
              ])
            );
            navigate('/user/channels/whatsapp');
          } else {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: 'Template creation failed. Please try again!',
                  status: 'Error',
                  duration: '',
                },
              ])
            );
          }
        })
        .catch((error) => {
          console.error('Error creating/updating template:', error);
        });
    }

    if (
      template === 'edit-template' &&
      templateDetails?.details?.status !== 'DRAFT'
    ) {
      UpdateTemplate({
        ...data,
        template_id: templateDetails?.details?.template_id,
      })
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: 'Template Updated successfully',
                  status: 'Success',
                  duration: '',
                },
              ])
            );
            navigate('/user/channels/whatsapp');
          } else {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: 'Template creation failed. Please try again!',
                  status: 'Error',
                  duration: '',
                },
              ])
            );
          }
        })
        .catch((error) => {
          console.error('Error creating/updating template:', error);
        });
    }
  };
  //** create and update template api call ends here *//

  // styles
  const btnStyle =
    'w-auto px-[0.8vw] py-[0.5vw] font-md weight-semibold rounded-[0.37vw]';

  return (
    <div
      className={`w-full max-h-[92vh] overflow-hidden ${
        loading ? 'cursor-not-allowed' : 'cursor-default'
      }`}
    >
      <div className='w-auto h-[3.7vw] bg-[var(--white)] m-[0.5vw] flex items-center justify-between pr-[1vw] mb-0'>
        <div className='flex items-center'>
          {template === 'edit-template' ? (
            <Tooltip title={templateDetails?.details?.name} trigger={['hover']}>
              <div className='!w-full !max-w-[30vw] h-full block truncate line-clamp-1 mx-[1vw] font-md weight-medium text-[var(--contentText)] cursor-default'>
                {templateDetails?.details?.name}
              </div>
            </Tooltip>
          ) : (
            <TemplateName
              type='edit'
              placeholder='Enter template name...'
              onChange={(val) => {
                templateName = val;
              }}
              // error={templateNameError}
              onErrorChange={(value) => {
                setTemplateNameError(value);
              }}
              allow={!loading}
            />
          )}
          <div className='flex items-center gap-[1.5vw] border-l-[0.08vw] border-[var(--card-border)] pl-[1.5vw]'>
            <h3 className='font-md weight-semibold text-[var(--font-600)]'>
              Category
            </h3>
            <Select
              rootClassName='w-[10vw] border-[var(--card-border)]'
              placeholder='Select Category'
              options={CATEGORIES_OPTIONS}
              value={selectedCategory}
              allow={!loading}
              position='bottom'
              onChange={(value) => {
                if (!loading) {
                  dispatch(update({ key: 'category', value: value?.value }));
                  setSelectedCategory(value);
                  if (value?.value === 'UTILITY') {
                    const res = buttons?.filter((b) => b.format === 'OPT_OUT');
                    if (res?.length > 0) {
                      updateButtons({
                        updatevalue: 'remove',
                        btnObj: { id: res?.[0]?.id },
                      });
                    }
                  }
                }
              }}
            />
          </div>
        </div>
        <div className='flex items-center gap-[0.5vw]'>
          <button
            className={`${btnStyle} text-[#616874] py-[0.4vw] hover:bg-[var(--BG-25)]`}
            onClick={() => {
              setIsDiscard(true);
            }}
          >
            Discard
          </button>
          <button
            className={`${btnStyle} ${
              checkAllowToSubmit()
                ? 'bg-[var(--primary)] text-[var(--white)] cursor-pointer'
                : 'bg-[var(--lightGrey)] text-[var(--darkgrey)] cursor-not-allowed'
            }`}
            onClick={() => {
              if (checkAllowToSubmit()) {
                createAndUpdateTemplateData(false);
              }
            }}
            disabled={!checkAllowToSubmit()}
          >
            {loading ? (
              <div className='w-[3vw] h-[1.15vw] flex-row align-center justify-center'>
                <Loader
                  Width='1vw'
                  Height='1vw'
                  loaderBg='4px solid white'
                  loaderColor='4px solid var(--primary)'
                />
              </div>
            ) : (
              <div className='w-[3vw] h-[1.15vw]'>Submit</div>
            )}
          </button>
        </div>
      </div>
      {templateNameError && (
        <div className='px-[1.5vw] font-sm weight-medium text-[var(--fontRed)] pt-[0.3vw]'>
          This name already exists, please enter a different one.
        </div>
      )}
      {(['edit-template', 'duplicate-template'].includes(template) &&
        templateDetails?.name === '') ||
      (template === 'create-template' && templateDetails?.name === '') ? (
        <div className='px-[1.5vw] font-sm weight-medium text-[var(--fontRed)] pt-[0.3vw]'>
          Template name can't be empty.
        </div>
      ) : (
        <></>
      )}
      <div className='w-full flex gap-[0.5vw] overflow-hidden p-[0.5vw]'>
        <div className='w-[20vw] bg-[var(--white)]'>
          {/* <MessagePreview
            header={header}
            body={body}
            headerVariable={headerVariable}
            bodyVariables={bodyVariables}
            ctaButtons={ctaButtons || []}
            customButtons={customButtons || []}
            footer={footer}
            order={buttonsOrder}
            showFooter={checkOptOutButton}
            allow={!loading}
            height={''}
          /> */}{' '}
          <div className='w-full font-default weight-semibold text-[var(--contentText)] px-[1vw] py-[1vw]'>
            Message Preview
          </div>
          <TemplatePreview
            header={header}
            headerVariable={headerVariable}
            body={body}
            bodyVariables={bodyVariables}
            footer={footer}
            buttons={buttons}
            buttonsOrder={buttonsOrder}
            ctaButtons={ctaButtons}
            customButtons={customButtons}
            data={templateDetails?.details}
            className={'rounded-none h-[67vh] max-[1600px]:h-[69vh]'}
          />
        </div>{' '}
        <div
          className={`w-[45.5vw] bg-[var(--white)] px-[0.7vw] py-[1vw] overflow-y-scroll ${
            templateNameError ? 'h-[72.5vh]' : 'h-[74.5vh]'
          }`}
        >
          <div className='border-b-[0.08vw] border-[var(--card-border)] pb-[1.5vw]'>
            <TemplateHeader
              header={header}
              headerVariable={headerVariable}
              dispatch={templateDispatch}
              allow={!loading}
            />
          </div>
          <div className='mt-[1.5vw] border-b-[0.08vw] border-[var(--card-border)] pb-[0.5vw]'>
            <TemplateBody
              language={selectedLanguage?.value ?? ''}
              body={body}
              dispatch={templateDispatch}
              headerVariable={headerVariable}
              variables={bodyVariables}
              allow={!loading}
              showEmojiPicker={showEmojiPicker}
              setShowEmojiPicker={setShowEmojiPicker}
            />
          </div>
          <div className='mt-[1.5vw]'>
            <TemplateFooter
              footer={footer}
              dispatch={templateDispatch}
              disabled={!checkOptOutButton}
              allow={!loading}
              text={footerText}
              setText={setFooterText}
            />
          </div>
          <div className='mt-[1.5vw] pt-[1.5vw] border-t-[0.08vw] border-[var(--card-border)]'>
            <TemplateButtons
              buttons={buttons}
              ctaButtons={ctaButtons}
              customButtons={customButtons}
              order={buttonsOrder}
              updateButtons={updateButtons}
              allow={!loading}
            />
          </div>
        </div>
        <div className='w-[14vw] bg-[var(--white)] px-[0.7vw]'>
          <h3 className='py-[1vw] border-b-[0.08vw] border-[var(--card-border)] font-default weight-semibold text-[var(--contentText)]'>
            Languages
          </h3>
          <div className='w-full py-[1vw]'>
            {template === 'edit-template' &&
            templateDetails?.details?.status === 'APPROVED' ? (
              <div className='font-md weight-medium border-[0.08vw] border-[var(--border-100)] rounded-[0.4vw] p-[0.5vw] outline-none cursor-not-allowed'>
                {selectedLanguage?.label}
              </div>
            ) : (
              <Select
                value={selectedLanguage}
                options={LANGUAGE_OPTIONS}
                placeholder={'Select language'}
                // multiple
                allow={!loading}
                rootClassName='border-[var(--card-border)]'
                optionsClassName='!max-w-[15vw]'
                cardStyle='!max-h-[1.5vw]'
                position='bottom'
                onChange={(val) => {
                  if (!loading) setSelectedLanguage(val);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Alert
        content='What would you like to do with the template?'
        open={isDiscard}
        setOpen={setIsDiscard}
        yesContent={'Discard'}
        noButtonClassName='!font-md !weight-semibold'
        // noDisabled={
        //   !(
        //     template === 'edit-template' &&
        //     templateDetails?.details?.status === 'DRAFT' &&
        //     templateDetails?.name?.length > 0 &&
        //     body?.text
        //   ) ||
        //   (template !== 'edit-template' &&
        //     body?.text &&
        //     templateDetails?.name?.length > 0)
        // }
        noContent={
          template === 'edit-template'
            ? templateDetails?.details?.status === 'DRAFT'
              ? 'Save as Draft'
              : null
            : 'Save as Draft'
        }
        handleNo={() => {
          createAndUpdateTemplateData(true);
          setIsDiscard(false);
        }}
        handleYes={() => {
          discard();
        }}
      />
    </div>
  );
}
