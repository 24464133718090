import React, { useState } from "react";
import { ICONS } from "../../../../../assets/icons";

const IndividualView = ({ response, segment }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${day}/${month}/${year}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const meridiem = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${meridiem}`;
  };

  const segmentView = segment?.settings?.segment_settings;

  const filename = response?.map((item) => item?.file_name);
  const send = segment?.settings?.send_setting;

  return (
    <>
      {segmentView?.map((data, index) => {
        const segments = data?.segments || [];
        return (
          <div
            className="SendSetting-container flex-column"
            key={data?.id || index}
          >
            <div className="flex-row align-center w-100 ">
              <div className="flex-row align-center">
                <img
                  src={ICONS?.BroadcastSegmentIcon}
                  alt=""
                  style={{ width: "25px", height: "25px" }}
                />
                <span className="pl-10">Contact list - {index + 1}</span>
              </div>
              <div className="count flex-row align-center w-[20vw] space-between p-5">
                <p className="">{filename[index]}</p>
              </div>
            </div>

            {/* <div className="flex-column">
              {data?.segments?.map((segment) => {
                return (
                  <React.Fragment key={segment?.id}>
                    <div className="pl-20">
                      <div className="flex-row w-100 ">
                        <div
                          className="  flex-row align-center"
                          style={{ width: "12%" }}
                        >
                          <img
                            src={ICONS?.BroadcastNewSegmentIcon}
                            alt=""
                            className="delay-image"
                          />
                          <p className="segment-name pl-10">
                            Segment - {segment?.id}
                          </p>
                        </div>
                        <div className="Delay-wrapper w-100  flex-row align-center  ">
                          <div
                            className="segment-containers flex-row align-center  pt-20 w-[25vw]"
                            style={{
                              border: "1px solid var(--font-50)",
                              height: "8vh",
                              borderRadius: "5px",
                            }}
                          >
                            <span className="detail">Audience Count</span>

                            <div className="flex-row align-center">
                              <div
                                className="w-100 space-between flex-row align-center"
                                style={{
                                  border: "1px solid var(--font-50)",
                                  padding: "10px",
                                  borderColor: "var(--font-50)",
                                  height: "5vh",
                                  width: "20vw",
                                  fontSize: "0.75vw",
                                  fontWeight: "600",
                                  color: "var(--textBlack)",
                                }}
                              >
                                <input defaultValue={segment?.end} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {send === "send_later" ? (
                        <div className="Calendar-container flex-row w-100 space-between">
                          {segment?.id > 1 && (
                            <div className="aaaa flex-row align-center  ">
                              <img
                                src={ICONS?.delayIcon}
                                alt="delay"
                                className="delay-image"
                              />

                              <span className="send_later pl-10">
                                Scheduled on
                              </span>

                              <div className="ml-10">
                                <div className="flex-row align-center pointer">
                                  <input
                                    iconPrefix={ICONS?.BroadcastCalendarIcon}
                                    className="date-time"
                                    defaultValue={formatDate(
                                      segment?.scheduled_at
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="broadcast-calendar flex-row align-center">
                                <div className="At p-5">@</div>

                                <div className="flex-row align-center pointer ml-10">
                                  <input
                                    iconPrefix={ICONS?.BroadcastTimerIcon}
                                    className="date-time"
                                    defaultValue={formatTime(
                                      segment?.scheduled_at
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="flex-column">
                          {segment?.id > 1 && index === 1 && (
                            <div className="flex-row">
                              <div className=" Delay-container flex-row align-center pl-5 ">
                                <img
                                  src={ICONS?.delayIcon}
                                  alt="delay"
                                  className="delay-image"
                                />
                                <p>Delay</p>
                              </div>
                              <div className="Delay-wrapper flex-row align-center pl-11 gap-5">
                                <span className="mt-1">Wait for</span>
                                <div
                                  className="flex-row align-center flex-end"
                                  style={{
                                    border: "1px solid var(--font-50)",
                                    height: "4.5vh",
                                    padding: "10px",
                                  }}
                                >
                                  <div className="flex-row flex-end align-center space-between ">
                                    <input
                                      defaultValue={segment.delay}
                                      className="no-spinner"
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid var(--border-50)",
                                    padding: "10px 30px 0px 5px ",
                                    fontSize: "0.75vw",
                                    fontWeight: "500",
                                    color: "var(--font-600)",
                                    width: "8vw",
                                    height: "4.5vh",
                                  }}
                                >
                                  <span>Minutes</span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div> */}
            <div className="flex-column resp">
              {segments?.map((segment, index) => {
                return (
                  <div className="pl-20" key={segment?.id}>
                    {segment?.id > 1 && (
                      <>
                        {send === "send_now" ? (
                          <div className="flex-column">
                            <div className="flex-row">
                              <div className=" Delay-container flex-row align-center pl-5 ">
                                <div className="flex-column align-center">
                                  <img
                                    src={ICONS?.delayIcon}
                                    alt="delay"
                                    className="delay-image"
                                  />
                                  <div className="line1" />
                                </div>

                                <p>Delay</p>
                              </div>

                              <div
                                className="Delay-wrapper w-100 flex-row align-center  pl-11 gap-5"
                                style={{ paddingLeft: "8%" }}
                              >
                                <span className="mt-1">Wait for</span>
                                <div
                                  className="flex-row align-center flex-end"
                                  style={{
                                    border: "1px solid var(--font-50)",
                                    height: "4.5vh",
                                    padding: "10px",
                                  }}
                                >
                                  <div className="flex-row flex-end align-center space-between">
                                    <span className="no-spinner">
                                      {segment.delay}
                                    </span>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    border: "1px solid var(--border-50)",
                                    padding: "10px 30px 0px 5px ",
                                    fontSize: "0.75vw",
                                    fontWeight: "500",
                                    color: "var(--font-600)",
                                    width: "8vw",
                                    height: "4.5vh",
                                  }}
                                >
                                  <span>Minutes</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="Calendar-container flex-row w-100 space-between">
                            <div className="aaaa flex-row align-center  ">
                              <div className="flex-column align-center">
                                <img
                                  src={ICONS?.delayIcon}
                                  alt="delay"
                                  className="delay-image"
                                />
                                <div className="line1" />
                              </div>

                              <span className="send_later pl-10">
                                Scheduled on
                              </span>

                              <div className="flex-row align-center pointer">
                                <input
                                  placeholder={"DD/MM/YY"}
                                  iconPrefix={ICONS?.BroadcastCalendarIcon}
                                  className="date-time"
                                  value={
                                    selectedDate[`${data.id}-${segment.id}`]
                                  }
                                  readOnly={true}
                                />
                              </div>
                              <div className="broadcast-calendar flex-row align-center">
                                <div className="At p-5">@</div>

                                <div className="flex-row align-center pointer ml-10">
                                  <input
                                    placeholder={"00.00 AM"}
                                    iconPrefix={ICONS?.BroadcastTimerIcon}
                                    className="date-time"
                                    value={
                                      selectedTime[`${data.id}-${segment.id}`]
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    <>
                      <div className="flex-row w-100 pt-10">
                        <div
                          className="  flex-row align-center"
                          style={{ width: "12%" }}
                        >
                          <div className="p-line flex-column  align-center justify-center h-60">
                            <img
                              src={ICONS?.BroadcastNewSegmentIcon}
                              alt=""
                              className="delay-image"
                            />

                            {index < segments.length - 1 && (
                              <div className="line" />
                            )}
                          </div>
                          <p className="segment-name pl-10">
                            Segment: {index + 1}
                          </p>
                        </div>
                        <div className="Delay-wrapper w-60  flex-row align-center  ">
                          <div className="segment-container flex-row align-center w-100 space-between pt-20">
                            <span className="detail">Audience Count</span>

                            <div className="increment w-100 flex-row align-center">
                              <div
                                key={segment.id}
                                style={{
                                  border: "1px solid var(--font-50)",
                                  padding: "10px",
                                }}
                                className="w-50"
                              >
                                <div
                                  className="w-100 space-between flex-row align-center"
                                  style={{
                                    fontSize: "0.75vw",
                                    fontWeight: "600",
                                    color: "var(--textBlack)",
                                  }}
                                >
                                  <span className="w-100">{segment?.end}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                );
              })}
            </div>
            {index === 0 && response?.length > 1 && send === "send_later" ? (
              <div className="Calendar-container flex-row w-100 space-between">
                {segment?.id > 1 && (
                  <div className="aaaa flex-row align-center  ">
                    <img
                      src={ICONS?.delayIcon}
                      alt="delay"
                      className="delay-image"
                    />

                    <span className="send_later pl-10">Scheduled on</span>

                    <div className="ml-10">
                      <div className="flex-row align-center pointer">
                        <input
                          iconPrefix={ICONS?.BroadcastCalendarIcon}
                          className="date-time"
                          defaultValue={formatDate(
                            segmentView?.[1]?.segments?.[0]?.scheduled_at
                          )}
                        />
                      </div>
                    </div>
                    <div className="broadcast-calendar flex-row align-center">
                      <div className="At p-5">@</div>

                      <div className="flex-row align-center pointer ml-10">
                        <input
                          iconPrefix={ICONS?.BroadcastTimerIcon}
                          className="date-time"
                          defaultValue={formatTime(
                            segmentView?.[1]?.segments?.[0]?.scheduled_at
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex-column">
                {index === 0 && response?.length > 1 && (
                  <div className="flex-row">
                    <div className=" Delay-container flex-row align-center pl-5 ">
                      <img
                        src={ICONS?.delayIcon}
                        alt="delay"
                        className="delay-image"
                      />
                      <p>Delay</p>
                    </div>
                    <div className="Delay-wrapper flex-row align-center pl-11 gap-5">
                      <span className="mt-1">Wait for</span>
                      <div
                        className="flex-row align-center flex-end"
                        style={{
                          border: "1px solid var(--font-50)",
                          height: "4.5vh",
                          padding: "10px",
                        }}
                      >
                        <div className="flex-row flex-end align-center space-between ">
                          <input
                            defaultValue={
                              segmentView?.[1]?.segments?.[0]?.delay
                            }
                            className="no-spinner"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          border: "1px solid var(--border-50)",
                          padding: "10px 30px 0px 5px ",
                          fontSize: "0.75vw",
                          fontWeight: "500",
                          color: "var(--font-600)",
                          width: "8vw",
                          height: "4.5vh",
                        }}
                      >
                        <span>Minutes</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default IndividualView;
