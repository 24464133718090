import {
  FacebookIcon,
  GoogleAdsIcon,
  InstagramIcon,
  LinkedinIcon,
  StoreIcon,
  OthersIcon,
  TiktokIcon,
  TwitterIcon,
  WebsiteIcon,
  WhatsappIcon,
} from '../../../assets/customSVG/CrmFilterIcons';
import Upload from '../../../assets/customSVG/Upload';
import Flower from '../../../assets/customSVG/Flower';
import Duplicate from '../../../assets/customSVG/Duplicate';
import BroadCastProfile from '../../../assets/customSVG/BroadCastProfile';
import RedCircleInfo from '../../../assets/customSVG/RedCircleInfo';
import { v4 as uuid } from 'uuid';
// import { ICONS } from '../../../assets/icons';
import Phone from '../../../assets/customSVG/Contact';
import Email from '../../../assets/customSVG/Email';

const crmFontColor = '#616874';
const primaryColor = 'var(--primary)';
const headingColor = '#2D3036';

// other defaults
const contactFilters = [
  {
    id: uuid(),
    label: 'WhatsApp',
    icon: WhatsappIcon,
    selected: false,
    access_name: 'whatsapp',
  },
  {
    id: uuid(),
    label: 'Facebook Ads',
    icon: FacebookIcon,
    selected: false,
    access_name: 'facebook_ads',
  },
  {
    id: uuid(),
    label: 'Instagram Ads',
    icon: InstagramIcon,
    selected: false,
    access_name: 'insta_ads',
  },
  {
    id: uuid(),
    label: 'Linked In Ads',
    icon: LinkedinIcon,
    selected: false,
    access_name: 'linkedin_ads',
  },
  {
    id: uuid(),
    label: 'Twitter Ads',
    icon: TwitterIcon,
    selected: false,
    access_name: 'x_ads',
  },
  {
    id: uuid(),
    label: 'TikTok Ads',
    icon: TiktokIcon,
    selected: false,
    access_name: 'tiktok_ads',
  },
  {
    id: uuid(),
    label: 'Google Ads',
    icon: GoogleAdsIcon,
    selected: false,
    access_name: 'google_ads',
  },
  {
    id: uuid(),
    label: 'Website',
    icon: WebsiteIcon,
    selected: false,
    access_name: 'website',
  },
  {
    id: uuid(),
    label: 'Store visit',
    icon: StoreIcon,
    selected: false,
    access_name: 'store_visit',
  },
  {
    id: uuid(),
    label: 'Others',
    icon: OthersIcon,
    selected: false,
    access_name: 'others',
  },
];

const lifeCycleStages = [
  {
    id: uuid(),
    label: 'lead',
    selected: false,
    access_name: 'lead',
  },
  {
    id: uuid(),
    label: 'contact',
    selected: false,
    access_name: 'contact',
  },
  {
    id: uuid(),
    label: 'opportunity',
    selected: false,
    access_name: 'opportunity',
  },
  {
    id: uuid(),
    label: 'won',
    selected: false,
    access_name: 'won',
  },
  {
    id: uuid(),
    label: 'lost',
    selected: false,
    access_name: 'lost',
  },
];

const csvInfo = [
  { id: 1, Icon: BroadCastProfile, label: 'Unique contacts' },
  { id: 2, Icon: Duplicate, label: 'Duplicate' },
  { id: 3, Icon: RedCircleInfo, label: 'Invalid' },
];

const ModelHeadings = {
  csv: {
    sideNavLabel: 'Import From CSV',
    mainLabel: ['Upload a CSV', 'Columns with matches'],
    Icon: Upload,
  },
  contact: {
    sideNavLabel: 'Single Contact',
    mainLabel: ['Add Contact'],
    Icon: Flower,
  },
};

// const contactsHeader = [
//   'name',
//   'phone',
//   'email',
//   'Contact source',
//   'Life cycle stage',
//   'created date',
//   'location',
//   'tags',
//   'country',
//   'last contacted',
//   'alternative mobile',
//   'first name',
//   'last name',
//   'whatsapp opt out',
//   'email opt out',
//   'SMS opt out',
// ];

const contactsHeaderWithId = [
  {
    label: 'name',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'phone',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'email',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'contact source',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'Life cycle stage',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'created date',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'location',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'tags',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'country',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'last contacted',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'alternative mobile',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'first name',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'last name',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'whatsapp opt out',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'email opt out',
    id: uuid(),
    default: false,
    selected: true,
  },
  {
    label: 'SMS opt out',
    id: uuid(),
    default: false,
    selected: true,
  },
];

const contactData = [
  {
    id: 1,
    name: {
      name: 'john santhosh',
      url: 'https://img.freepik.com/premium-photo/creative-logo-design_113255-32322.jpg',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'whatsapp',
    'Life cycle stage': 'whatsapp',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#003285',
      },
      {
        label: 'location',
        color: '#FF7F3E',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country:
      'india asdkfas asdasd fa sdfadjkshf askdf asdhfa osidhfaoishdfaisdhf a',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': false,
    'SMS opt out': true,
  },
  {
    id: 2,
    name: {
      name: 'agent romanoff',
      url: 'https://img.freepik.com/premium-photo/creative-logo-design_113255-33183.jpg',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'instagram',
    'Life cycle stage': 'whatsapp',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#79155B',
      },
      {
        label: 'location',
        color: '#A63EC5',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': '',
    'whatsapp opt out': false,
    'email opt out': true,
    'SMS opt out': '',
  },
  {
    id: 3,
    name: {
      name: 'peter Parker',
      url: 'https://img.freepik.com/premium-photo/creative-logo-design_113255-29410.jpg',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'store',
    'Life cycle stage': 'Lead',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#1f1f',
      },
      {
        label: 'location',
        color: '#47d',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india er',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': '',
    'email opt out': true,
    'SMS opt out': true,
  },
  {
    id: 4,
    name: {
      name: 'Iron man',
      url: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'facebook',
    'Life cycle stage': 'Opportunities',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#79155B',
      },
      {
        label: 'location',
        color: '#A63EC5',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': '',
    'SMS opt out': '',
  },
  {
    id: 5,
    name: {
      name: 'Hulk buster',
      url: 'https://img.freepik.com/free-psd/mandala-fractal-design-element-with-flower-pattern-isolated-transparent-background_191095-20655.jpg?w=1060&t=st=1716361760~exp=1716362360~hmac=3a914b8236909bfcc1da1efb7c6bb6b4d3c0147c87e12aec026a4f00d50d72ee',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'googleAds',
    'Life cycle stage': 'Won',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#003285',
      },
      {
        label: 'location',
        color: '#FF7F3E',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': '',
    'SMS opt out': '',
  },
  {
    id: 6,
    name: {
      name: 'Rob stark',
      url: 'https://img.freepik.com/free-vector/ornamental-mandala_24877-54650.jpg?w=1060&t=st=1716361798~exp=1716362398~hmac=4aba994cb279027d6cb50e115d637177bf3c25475849c3c5ee6cc20d49077ae3',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': '',
    'Life cycle stage': '',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#1f1f',
      },
      {
        label: 'location',
        color: '#47d',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': '',
    'SMS opt out': '',
  },
  {
    id: 7,
    name: {
      name: 'Tyrion lion',
      url: '',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'others',
    'Life cycle stage': 'Lost',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#1f1f',
      },
      {
        label: 'location',
        color: '#47d',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': '',
    'SMS opt out': '',
  },
  {
    id: 8,
    name: {
      name: 'john',
      url: 'https://img.freepik.com/premium-photo/creative-logo-design_113255-29410.jpg',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'others',
    'Life cycle stage': 'whatsapp',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#1f1f',
      },
      {
        label: 'location',
        color: '#47d',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': '',
    'SMS opt out': '',
  },
  {
    id: 9,
    name: {
      name: 'john',
      url: 'https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cGVvcGxlfGVufDB8fDB8fHww',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'whatsapp',
    'Life cycle stage': 'whatsapp',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#1f1f',
      },
      {
        label: 'location',
        color: '#47d',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': '',
    'SMS opt out': '',
  },
  {
    id: 10,
    name: {
      name: 'john',
      url: 'https://img.freepik.com/premium-photo/creative-logo-design_113255-29410.jpg',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'whatsapp',
    'Life cycle stage': 'whatsapp',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#1f1f',
      },
      {
        label: 'location',
        color: '#47d',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': '',
    'SMS opt out': '',
  },
  {
    id: 11,
    name: {
      name: 'john',
      url: '',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'whatsapp',
    'Life cycle stage': 'whatsapp',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#1f1f',
      },
      {
        label: 'location',
        color: '#47d',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': '',
    'SMS opt out': '',
  },
  {
    id: 12,
    name: {
      name: 'john',
      url: 'https://img.freepik.com/free-psd/mandala-fractal-design-element-with-flower-pattern-isolated-transparent-background_191095-20655.jpg?w=1060&t=st=1716361760~exp=1716362360~hmac=3a914b8236909bfcc1da1efb7c6bb6b4d3c0147c87e12aec026a4f00d50d72ee',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'whatsapp',
    'Life cycle stage': 'whatsapp',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#1f1f',
      },
      {
        label: 'location',
        color: '#47d',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': '',
    'SMS opt out': '',
  },
  {
    id: 13,
    name: {
      name: 'john',
      url: '',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'whatsapp',
    'Life cycle stage': 'whatsapp',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#1f1f',
      },
      {
        label: 'location',
        color: '#47d',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': '',
    'SMS opt out': '',
  },
  {
    id: 14,
    name: {
      name: 'john',
      url: '',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'whatsapp',
    'Life cycle stage': 'whatsapp',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#1f1f',
      },
      {
        label: 'location',
        color: '#47d',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': '',
    'SMS opt out': '',
  },
  {
    id: 15,
    name: {
      name: 'john',
      url: 'https://img.freepik.com/premium-photo/creative-logo-design_113255-29410.jpg',
    },
    phone: '(+91) 987 654 3210',
    email: 'john@aptonworks.com',
    'contact source': 'whatsapp',
    'Life cycle stage': 'whatsapp',
    'created date': 'Wed May 22 2024',
    location: 'chennai, Tamilnadu',
    tags: [
      {
        label: 'Travel',
        color: '#1f1f',
      },
      {
        label: 'location',
        color: '#47d',
      },
      {
        label: 'Lorem',
        color: '#9936',
      },
      {
        label: 'Vlog',
        color: '#234',
      },
      {
        label: 'ipsum',
        color: '#553',
      },
    ],
    country: 'india',
    'last contacted': 'Wed May 22 2024',
    'alternative mobile': '+91 9879321124',
    'first name': 'john',
    'last name': 'santhosh',
    'whatsapp opt out': 'true',
    'email opt out': '',
    'SMS opt out': '',
  },
];

const filterKeys = [
  { id: uuid(), name: 'name' },
  { id: uuid(), name: 'phone' },
  { id: uuid(), name: 'email' },
  { id: uuid(), name: 'lead_source' },
  { id: uuid(), name: 'created_date' },
  { id: uuid(), name: 'tags' },
  { id: uuid(), name: 'location' },
  { id: uuid(), name: 'country' },
  { id: uuid(), name: 'last_contacted' },
  { id: uuid(), name: 'alternative_mobile' },
  { id: uuid(), name: 'first_name' },
  { id: uuid(), name: 'last_name' },
];

const mainFilters = {
  name: {
    props: {
      check: ['empty', 'not_empty'],
      type: 'text',
      inputType: 'text',
    },
    filters: {
      contains: '',
      not_contains: '',
      starts_with: '',
      ends_with: '',
      is_not: '',
      is: '',
      empty: '',
      not_empty: '',
    },
  },
  phone: {
    props: {
      check: ['empty', 'not_empty'],
      type: 'text',
      inputType: 'number',
    },
    filters: {
      contains: '',
      not_contains: '',
      is_not: '',
      is: '',
      empty: '',
      not_empty: '',
    },
  },
  email: {
    props: {
      check: ['empty', 'not_empty'],
      type: 'text',
      inputType: 'text',
    },
    filters: {
      contains: '',
      not_contains: '',
      is_not: '',
      is: '',
      empty: '',
      not_empty: '',
    },
  },
  lead_source: {
    props: {
      check: ['empty', 'not_empty'],
      type: 'dropdown',
      data: contactFilters,
    },
    filters: {
      is: '',
      is_not: '',
      empty: '',
      not_empty: '',
    },
  },
  created_date: {
    props: {
      check: [],
      type: 'date',
      inputType: 'date',
    },
    filters: {
      is: '',
      is_not: '',
      before: '',
      after: '',
    },
  },
  tags: {
    props: {
      check: ['empty', 'not_empty'],
      type: 'tags',
    },
    filters: {
      is: '',
      is_not: '',
      empty: '',
      not_empty: '',
    },
  },
  location: {
    props: {
      check: ['empty', 'not_empty'],
      type: 'text',
      inputType: 'text',
    },
    filters: {
      contains: '',
      not_contains: '',
      is_not: '',
      is: '',
      empty: '',
      not_empty: '',
    },
  },
  country: {
    props: { check: ['empty', 'not_empty'], type: 'text', inputType: 'text' },
    filters: {
      contains: '',
      not_contains: '',
      is_not: '',
      is: '',
      empty: '',
      not_empty: '',
    },
  },
  last_contacted: {
    props: {
      check: [],
      type: 'date',
      inputType: 'date',
    },
    filters: { is: '', is_not: '', empty: '', not_empty: '' },
  },
  alternative_mobile: {
    props: {
      check: ['empty', 'not_empty'],
      type: 'text',
      inputType: 'number',
    },
    filters: {
      contains: '',
      not_contains: '',
      is_not: '',
      is: '',
      empty: '',
      not_empty: '',
    },
  },
  first_name: {
    props: {
      check: ['empty', 'not_empty'],
      type: 'text',
      inputType: 'text',
    },
    filters: {
      contains: '',
      not_contains: '',
      starts_with: '',
      ends_with: '',
      is_not: '',
      is: '',
      empty: '',
      not_empty: '',
    },
  },
  last_name: {
    props: { check: ['empty', 'not_empty'], type: 'text', inputType: 'text' },
    filters: {
      contains: '',
      not_contains: '',
      starts_with: '',
      ends_with: '',
      is_not: '',
      is: '',
      empty: '',
      not_empty: '',
    },
  },
};

const singleContactFields = {
  'profile information': [
    {
      id: uuid(),
      label: 'first_name',
      icon: '',
      editable: true,
      access_name: 'first_name',
    },
    {
      id: uuid(),
      label: 'last_name',
      icon: '',
      editable: true,
      access_name: 'last_name',
    },
    {
      id: uuid(),
      label: 'phone_number',
      icon: Phone,
      editable: true,
      access_name: 'phone_number',
    },
    {
      id: uuid(),
      label: 'alternative_mobile',
      icon: Phone,
      editable: true,
      access_name: 'first_name',
    },
    {
      id: uuid(),
      label: 'email_ID',
      icon: Email,
      editable: true,
      access_name: 'email',
    },
    {
      id: uuid(),
      label: 'work_email_ID',
      icon: Email,
      editable: true,
      access_name: 'wk_email',
    },
    { id: uuid(), label: 'age', icon: '', editable: true, access_name: 'age' },
    {
      id: uuid(),
      label: 'gender',
      icon: '',
      editable: true,
      access_name: 'gender',
    },
    {
      id: uuid(),
      label: 'profession',
      icon: '',
      editable: true,
      access_name: 'profession',
    },
    {
      id: uuid(),
      label: 'contact_source',
      icon: '',
      editable: true,
      access_name: 'contact_src',
      type: 'dropdown',
      data: [...contactFilters],
    },
    {
      id: uuid(),
      label: 'life_cycle_stage',
      icon: '',
      editable: true,
      access_name: 'lc_stage',
      type: 'dropdown',
      data: [...lifeCycleStages],
    },
    {
      id: uuid(),
      label: 'assignee',
      icon: '',
      editable: true,
      access_name: '',
      type: 'dropdown',
      data: [...contactFilters],
    },
    {
      id: uuid(),
      label: 'created_date',
      icon: '',
      editable: false,
      access_name: 'created_at',
    },
    {
      id: uuid(),
      label: 'last_contacted',
      icon: '',
      editable: false,
      access_name: 'last_contacted',
    },
  ],
  'address information': [
    { id: uuid(), label: 'street', editable: true, access_name: 'street' },
    { id: uuid(), label: 'city', editable: true, access_name: 'city' },
    { id: uuid(), label: 'state', editable: true, access_name: 'state' },
    {
      id: uuid(),
      label: 'zip/Postal code',
      editable: true,
      access_name: 'zip',
    },
    { id: uuid(), label: 'country', editable: true, access_name: 'country' },
  ],
  tags: null,
  'opt out': [
    {
      id: uuid(),
      label: 'whatsapp',
      selected: true,
      access_name: 'opt_wap',
      date: '2024-06-11T05:43:55.896Z',
    },
    {
      id: uuid(),
      label: 'email',
      selected: false,
      access_name: 'opt_email',
      date: '2024-06-10T05:43:55.896Z',
    },
    {
      id: uuid(),
      label: 'SMS',
      selected: false,
      access_name: 'opt_sms',
      date: '2024-06-03T05:43:55.896Z',
    },
  ],
  'social profile': [
    {
      id: uuid(),
      label: 'facebook',
      icon: FacebookIcon,
      editable: true,
      access_name: 'prof_fb',
    },
    {
      id: uuid(),
      label: 'instagram',
      icon: InstagramIcon,
      editable: true,
      access_name: 'prof_ig',
    },
    {
      id: uuid(),
      label: 'twitter(X)',
      icon: TwitterIcon,
      editable: true,
      access_name: 'prof_x',
    },
    {
      id: uuid(),
      label: 'linkedIn',
      icon: LinkedinIcon,
      editable: true,
      access_name: 'prof_linkedin',
    },
    {
      id: uuid(),
      label: 'tikTok',
      icon: TiktokIcon,
      editable: true,
      access_name: 'prof_tiktok',
    },
  ],
};

const selectedContactData = contactData.map((item, i) => {
  return { id: item.id, selected: false };
});
const singleContactNav = [
  { id: uuid(), selected: true, label: 'overview' },
  { id: uuid(), selected: false, label: 'chat history' },
  { id: uuid(), selected: false, label: 'conversation insights' },
  { id: uuid(), selected: false, label: 'customer journey' },
  { id: uuid(), selected: false, label: 'attachments' },
  { id: uuid(), selected: false, label: 'invoice details' },
  { id: uuid(), selected: false, label: 'tickets' },
];
export {
  filterKeys,
  mainFilters,
  contactFilters,
  crmFontColor,
  primaryColor,
  ModelHeadings,
  headingColor,
  contactData,
  contactsHeaderWithId,
  singleContactNav,
  singleContactFields,
  selectedContactData,
  csvInfo,
};
