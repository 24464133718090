import React, { useEffect, useState } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import './style.css';

// API
import { createWABA, retryCreateWABA } from 'api/apiClients/meta/whatsapp';
import { currentUserDetails } from '../../../../api/Api';

// hooks
import useToggle from 'hooks/useToggle';

// redux
import bg from 'assets/images/WABA_not_connected.png';
import {
  updateCurrentBrand,
  updateCurrentUser,
} from '../../../../reduxToolkit/appSlice';
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../test/jest-redux-hooks';

// components
import NoData from 'components/commonComponents/Nodata/index';
import CreateWABALoader from 'components/commonComponents/createWABALoader';
import CreateWABAError from 'components/commonComponents/createWABALoader/CreateWABAError';

const Whatsapp = () => {
  const { pathname } = useLocation();
  const dispatch = useAspDispatch();

  const navigate = useNavigate();

  // create WABA
  const [sessionData, setSessionData] = useState(null);
  const [data, setData] = useState(null);
  const [isRes, setIsRes] = useToggle(false);
  const [isResError, setIsResError] = useState({
    error: false,
    data: '',
  });
  const [isLoading, setIsLoading] = useToggle(false);
  const [res, setRes] = useState(null);

  const [syncing, setSyncing] = useOutletContext();

  const { currentBrand, currentUser } = useAspSelector((state) => state?.app);

  useEffect(() => {
    window.addEventListener('message', sessionInfoListener);

    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };
  }, []);

  useEffect(() => {
    if (sessionData && data) {
      const payload = {
        brand_id: currentBrand?.brand_id,
        waba_id: sessionData?.waba_id,
        phone_number_id: sessionData?.phone_number_id,
        code: data?.authResponse?.code,
      };
      createWhatsappAccount(payload);
    }
  }, [data, sessionData]);

  const sessionInfoListener = (event) => {
    if (
      event.origin !== 'https://www.facebook.com' &&
      event.origin !== 'https://web.facebook.com'
    ) {
      return;
    }
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        if (data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data;
          setSessionData(data?.data);
        } else {
          const { current_step } = data.data;
        }
      }
    } catch {
      console.warn('Non JSON Response', event.data);
    }
  };

  // launch the meta embedded signup window
  async function launchWhatsAppSignup() {
    let data = null;
    await FB.login(
      function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code;
          setData(response);
        } else {
          console.warn('User cancelled login or did not fully authorize.');
        }
      },
      {
        config_id: '820380526237844',
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          feature: 'whatsapp_embedded_signup',
          sessionInfoVersion: 2,
          setup: {},
        },
      }
    );

    return data;
  }

  // connecting the webhook
  const createWhatsappAccount = async (payload) => {
    setIsRes(true);
    setIsResError(false);
    try {
      const res = await createWABA(payload);
      setRes(res?.data);
      if (res?.status === 200) {
        await fetchCurrentUserDetails();
        setIsRes(false);
        // setIsResError(false);
      } else {
        const data = {
          error: true,
          data: res?.response?.data?.error,
        };
        setIsResError(data);
      }
    } catch (e) {
      console.warn(e);
      setRes(null);
    }
  };

  // if failed to connect the webhook
  const retryWABACreate = async () => {
    setIsRes(true);
    setIsResError({
      error: false,
      data: '',
    });
    try {
      const res = await retryCreateWABA({ brand_id: currentBrand?.brand_id });
      if (res.status === 200) {
        const newRes = await fetchCurrentUserDetails();
        if (newRes?.status === 200) {
          window.location.reload();
          setIsRes(false);
        }
        await fetchCurrentUserDetails();
        setIsRes(false);
      } else {
        setIsResError({
          error: true,
          data: res?.response?.data?.error,
        });
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const fetchCurrentUserDetails = async () => {
    try {
      const res = await currentUserDetails();
      dispatch(updateCurrentUser(res?.data));
      const checkBrandExist = res?.data?.active_accounts?.[0]?.brands?.filter(
        (b) =>
          b?.brand_id === currentBrand?.brand_id &&
          b?.is_whatsapp_connected !== currentBrand?.is_whatsapp_connected
      );

      setIsRes(false);
      if (checkBrandExist?.length > 0) {
        dispatch(updateCurrentBrand(checkBrandExist?.[0]));
        dispatch(updateCurrentUser(res?.data));
      } else {
        dispatch(
          updateCurrentBrand(res?.data?.active_accounts?.[0]?.brands?.[0])
        );
      }
    } catch (error) {
      setIsRes(false);
      console.warn('Error fetching current user details:', error);
    }
  };

  return (
    <div className='w-full h-full'>
      {currentBrand?.is_whatsapp_connected === true ? (
        <>
          <Outlet context={[syncing, setSyncing]} />
        </>
      ) : (
        <div
          className='w-full h-full flex-row align-center justify-center'
          style={{
            backgroundImage: `url(${bg})`,
          }}
        >
          <div className='flex-row align-center justify-center'>
            <div className='flex-column align-center justify-center'>
              <div className='w-full h-full flex-center flex-col'>
                {isRes ? (
                  <div
                    className={`w-full h-full absolute top-0 left-0 flex flex-col items-center justify-center `}
                  >
                    {isResError?.error ? (
                      <>
                        <CreateWABAError width={'11vw'} height={'6vw'} />
                        <p
                          className={`font-large weight-semibold mt-6 ${
                            ['payment', 'two-factor']?.includes(
                              isResError?.data
                            )
                              ? 'text-[var(--primary)]'
                              : 'text-[#F96056]'
                          }`}
                        >
                          {isResError?.data === 'payment'
                            ? 'Please configure the payment method and click "continue".'
                            : isResError?.data === 'two-factor'
                            ? 'Please remove your 2-factor authentication from the WhatsApp manager and click continue.'
                            : 'Unexpected error happen, please retry!'}
                        </p>
                        <button
                          className={`font-md weight-medium text-[#F96056] font-normal weight-bold px-[0.85vw] py-[0.4vw] rounded-[0.6vw] mt-[0.8vw] ${
                            ['payment', 'two-factor']?.includes(
                              isResError?.data
                            )
                              ? 'bg-[var(--primary)] text-white'
                              : 'bg-red-500 text-white'
                          }`}
                          onClick={() => {
                            retryWABACreate();
                          }}
                        >
                          {['payment', 'two-factor']?.includes(isResError?.data)
                            ? 'Continue'
                            : 'Retry'}
                        </button>
                      </>
                    ) : (
                      <>
                        <CreateWABALoader width={'11vw'} height={'6vw'} />
                        <p className='font-large weight-semibold bg-gradient-to-r from-[#0ACD95] to-[#6940F2] bg-clip-text text-transparent'>
                          Hold on, we&apos;re setting up things for you.
                        </p>
                      </>
                    )}
                  </div>
                ) : (
                  <div className='w-full h-full flex-center flex-col'>
                    <NoData
                      content={
                        <>
                          You have not connected any account yet,
                          <br /> click on the button to get started.
                        </>
                      }
                      rootClassName='h-auto'
                      iconStyle={'w-[15vw]'}
                      style={'max-w-[27vw]'}
                    />
                    <button
                      className='bg-[var(--primary)] text-[var(--white)] font-normal weight-bold px-[0.85vw] py-[0.5vw] rounded-[0.6vw] mt-[0.8vw]'
                      onClick={() => {
                        launchWhatsAppSignup();
                      }}
                    >
                      Connect WhatsApp
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Whatsapp;
