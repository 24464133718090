import React, { useCallback } from 'react';

// assets
import Mail from 'assets/customSVG/Mail';
import FullArrow from '../../../../assets/customSVG/FullArrow';
import AddIcon from '../../../../assets/customSVG/AddIcon';

// helper
import { cn } from '../../../../helper/cn';
import { useNavigate } from 'react-router-dom';
import { InviteTeamMembers } from '../../api/OnBoarding';
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../test/jest-redux-hooks';

// components
import Loader from '../../../../components/commonComponents/Loader/Index';
import { updateToggleToast } from '../../../../reduxToolkit/appSlice';

export default function TeamInvite({
  data,
  onChange,
  fetchCurrentUserDetails,
  isLoading,
  setIsLoading,
}) {
  const { currentAccount, currentUser, toggleToast } = useAspSelector(
    (state) => state.app
  );
  const dispatch = useAspDispatch();

  const navigate = useNavigate();

  const validation = useCallback(() => {
    const res = data?.list?.filter((l) => l.value);
    return res?.length > 0;
  }, [data]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
      const emails = data?.list?.map((l) => l.value);
      const filterEmails = emails?.filter((e) => e !== '');
      const datas = {
        email_list: filterEmails,
        brand_id: currentUser?.active_accounts?.[0]?.brands?.[0]?.brand_id,
        role_id: 'B_MNGR',
      };
      const res = await InviteTeamMembers(datas);
      if (res?.status === 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: 'Invitations send successfully',
              status: 'Success',
              duration: '',
            },
          ])
        );
        onChange('reset_teams');
      } else {
        setIsLoading(false);
      }
    }
  };

  // styles
  const label = 'text-base weight-semibold leading-6 text-[#2D3036]';
  const inputContainer =
    'w-full h-12 flex items-center gap-2 py-2.5 px-3 mt-6 border border-[#D1D3D8] rounded-lg';
  const input = 'w-full text-sm weight-medium';

  return (
    <div className='w-full mt-6 mx-auto'>
      <h3 className={cn(label)}>Let's invite members to your brand</h3>
      <form
        className='w-full flex flex-col items-center'
        onSubmit={handleSubmit}
      >
        {data?.list?.map((m) => {
          return (
            <div className={cn(inputContainer)}>
              <div>
                <Mail width='18px' height='18px' />
              </div>
              <input
                name='mail'
                id='mail'
                type='email'
                placeholder='Ex:- yourmail@email.com'
                className={cn(input)}
                value={m.value}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChange('update_teams', null, m.id, value);
                }}
              />
            </div>
          );
        })}

        <div
          className={cn(
            'w-full text-sm weight-semibold mt-6 flex items-center justify-start'
          )}
        >
          <button
            className={cn(
              'flex items-center',
              data?.list.length < 5
                ? 'text-[var(--primary)] cursor-pointer'
                : 'text-[var(--font-600)] cursor-not-allowed'
            )}
            onClick={() => {
              if (data?.list.length < 5) onChange('add_teams');
            }}
          >
            <AddIcon
              stroke={
                data?.list.length < 5 ? 'var(--primary)' : 'var(--font-600)'
              }
            />{' '}
            Add New
          </button>
        </div>

        <div className='flex justify-center'>
          <button
            type='submit'
            className={cn(
              'w-44 h-12 rounded-[10px] px-4 py-2 text-base weight-semibold mt-9 flex items-center justify-center',
              validation()
                ? isLoading
                  ? 'cursor-not-allowed bg-[var(--primary)] text-[var(--white)]'
                  : 'bg-[var(--primary)] text-[var(--white)] cursor-pointer'
                : 'bg-[var(--BG-100)] text-[var(--font-600)] cursor-not-allowed'
            )}
          >
            {isLoading ? (
              <div className='size-[2vw] flex items-center justify-center'>
                <Loader
                  Width='20px'
                  Height='20px'
                  loaderBg='3px solid white'
                  loaderColor='3px solid var(--primary)'
                />
              </div>
            ) : (
              'Invite Members'
            )}
          </button>
        </div>
      </form>
      <div
        className='flex items-center justify-center gap-2 mt-9 cursor-pointer'
        onClick={() => {
          navigate('/user/home');
        }}
      >
        <p className='text-base weight-semibold text-[#898E99]'>
          Skip & Go to Dashboard
        </p>
        <div className=''>
          <FullArrow color='#898E99' />
        </div>
      </div>
    </div>
  );
}
