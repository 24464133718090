import React, { useEffect, useState } from 'react';
import '../styles/Brand.css';

// assets
import Delete from '../../../assets/customSVG/Delete';
import Share from '../../../assets/customSVG/Share';
import Xmark from '../../../assets/customSVG/Xmark';

// API
import { BrandsList } from '../api/Api';

// helpers
import { dateConverter } from '../../../helper/dateConverter';

// components
import {
  brandCategory,
  BrandInviteList,
} from '../../../constant/app/brand/brand';
import BrandMember from './BrandMember';
import { useAspSelector } from '../../../test/jest-redux-hooks';

// sttyles
const BrandStyle = {
  width: `calc(100% / 4)`,
};

const STATUS_COLOR = {
  10: {
    bgColor: 'var(--primaryBackground)',
    color: 'var(--TextPrimary)',
  },
  Inactive: {
    bgColor: 'var(--lightRed)',
    color: 'var(--fontRed)',
  },
  1: {
    bgColor: 'var(--primaryOpacity)',
    color: 'var(--a)',
  },
  Expired: {
    bgColor: 'var(--font-50)',
    color: 'var(--font-600)',
  },
};

const InviteMember = () => {
  const { currentBrand } = useAspSelector((state) => state.app);
  const [brandsList, setBrandsList] = useState([]);

  useEffect(() => {
    if (currentBrand) fetchBrandList(currentBrand?.brand_id);
  }, [currentBrand]);

  const fetchBrandList = async (brandId) => {
    try {
      const res = await BrandsList(brandId);
      if (res?.status === 200) {
        setBrandsList(res?.data);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const roleId = {
    4: 'Brand Manager',
    5: 'Campaigns Manager',
    6: 'Content Manager',
  };

  const STATUS = {
    '-1': 'Deleted',
    1: 'Pending',
    10: 'Active',
  };

  return (
    <div className=''>
      <div className='flex items-center px-2.5 py-4'>
        <p className='text-[0.95vw] weight-semibold'>Invited members</p>
      </div>
      <div className='w-full h-full rounded-2xl flex flex-col bg-[var(--BG-50)] py-2.5 pl-2.5 pr-0.5'>
        {brandsList?.invites_users?.length > 0 ? (
          <>
            <div className='w-full flex items-center font-default weight-semibold py-3 text-[#2D3036]'>
              <div className='w-2/5 pl-2.5'>Name</div>
              <div className='w-3/5 flex items-center justify-between text-center gap-5'>
                <div style={BrandStyle}>Created</div>
                <div style={BrandStyle}>Role</div>
                {/* <div style={BrandStyle}>Last Active</div>
                <div style={BrandStyle}>Status</div> */}
              </div>
            </div>
            <div className='w-full h-[50vh] flex flex-col gap-2.5 overflow-hidden overflow-y-scroll pr-2 listScroll'>
              {brandsList?.invites_users?.map((item, id) => {
                const nameConvertion =
                  item?.to_first_name && item?.to_last_name
                    ? `${item?.to_first_name} ${item?.to_last_name}`
                    : item?.to_first_name
                    ? item?.to_first_name
                    : item?.to_last_name
                    ? item?.to_last_name
                    : '--';
                const email = item?.to_email ? item?.to_email : '';

                const name =
                  nameConvertion !== '--'
                    ? nameConvertion
                        ?.split(' ')
                        ?.map((w) => w.slice(0, 1).toString())
                    : email?.split(' ')?.map((w) => w.slice(0, 1).toString());

                const isExpired =
                  item?.status === 1 &&
                  dateConverter(item?.invite_token_expire_at)
                    ?.formatedDateWithYear <=
                    dateConverter()?.formatedDateWithYear
                    ? true
                    : false;

                return (
                  <div
                    className={`w-full h-fit min-h-16 flex items-center py-2 mx-px rounded-xl bg-[var(--background)] relative group `}
                  >
                    <div className='w-2/5 p-2.5 flex items-center cursor-pointer'>
                      <div
                        className='size-10 min-w-10 rounded-full flex items-center justify-center font-sm weight-semibold text-[var(--contentText)] capitalize'
                        style={{
                          background: 'var(--BG-50)',
                        }}
                      >
                        {name}
                      </div>

                      <div className='w-full h-[52px] flex flex-col justify-center gap-1 pl-2.5'>
                        <div className='flex items-center'>
                          {nameConvertion && (
                            <p className='max-w-[60%] truncate font-default weight-semibold text-[var(--contentText)]'>
                              {nameConvertion}
                            </p>
                          )}
                          {[1, 'Expired'].includes(item?.status) && (
                            <div className='items-center gap-2.5 px-2.5 hidden group-hover:!flex'>
                              <button
                                className={`w-6 h-fit  group/delete bg-transparent flex items-center justify-center rounded-[0.4vw]`}
                                onClick={() => {}}
                              >
                                <Delete
                                  width={16}
                                  height={16}
                                  className={`stroke-[#898E99] group-hover/delete:stroke-[var(--fontRed)]`}
                                  color=''
                                />
                              </button>
                              <button
                                className={`w-6 h-fit  group/delete bg-transparent flex items-center justify-center rounded-[0.4vw]`}
                                onClick={() => {}}
                              >
                                <Share
                                  width={17}
                                  height={17}
                                  className={`fill-[#898E99] group-hover/delete:fill-[var(--contentText)]`}
                                  color=''
                                  strokeWidth='0'
                                />
                              </button>
                              <button
                                className={`w-6 h-fit  group/close bg-transparent flex items-center justify-center rounded-[0.4vw]`}
                                onClick={() => {}}
                              >
                                <Xmark
                                  width={10}
                                  height={10}
                                  className={`stroke-[#898E99] group-hover/close:stroke-[var(--fontRed)]`}
                                  color=''
                                />
                              </button>
                            </div>
                          )}
                        </div>
                        <div className='w-full flex-1 flex items-center text-[0.69vw] weight-medium text-[var(--channelToggleSwitch)]'>
                          <p className='max-w-[90%] truncate pr-4 font-md weight-semibold text-[var(--font-400)]'>
                            {item?.to_email}
                          </p>
                          <p
                            className='px-2 py-0.5 h-fit rounded-full flex items-center justify-center font-xs weight-semibold'
                            style={{
                              backgroundColor: isExpired
                                ? STATUS_COLOR['Expired']?.bgColor
                                : STATUS_COLOR[item?.status]?.bgColor,
                              color: isExpired
                                ? STATUS_COLOR['Expired']?.color
                                : STATUS_COLOR[item?.status]?.color,
                            }}
                          >
                            {isExpired ? 'Expired' : STATUS[item?.status]}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-3/5 h-full flex items-center justify-between text-center font-md weight-medium gap-5 '>
                      <div
                        style={BrandStyle}
                        className='flex items-center justify-center'
                      >
                        {item?.created_at
                          ? dateConverter(item?.created_at)
                              ?.formatedDateWithoutYear
                          : '--'}
                      </div>

                      <div
                        style={BrandStyle}
                        className='flex items-center justify-center'
                      >
                        {roleId[item.role_id]}
                        {/* <BrandMember MemberRole={item?.Role} open /> */}
                      </div>

                      {/* <div
                      style={BrandStyle}
                      className='flex items-center justify-center'
                    >
                      {item?.Time}
                    </div> */}

                      {/* <div
                      className='flex items-center justify-center'
                      style={BrandStyle}
                    >
                      <label class='flex items-center relative w-max cursor-pointer select-none'>
                        <input
                          type='checkbox'
                          class='appearance-none transition-colors cursor-pointer w-12 h-5 rounded-full bg-[var(--BG-50)] outline-none'
                          checked={
                            ['Expired', 'Inactive'].includes(item?.status)
                              ? false
                              : true
                          }
                          onChange={() => {
                            const res = brandsList?.invites_sent?.map((d) => {
                              if (item.id === d.id)
                                if (
                                  ['Expired', 'Inactive'].includes(item?.status)
                                )
                                  return { ...d, status: 'Active' };
                                else return { ...d, status: 'Inactive' };
                              return d;
                            });
                            setBrandsList({ invites_sent: res });
                          }}
                        />
                        {['Expired', 'Inactive'].includes(item?.status) ? (
                          <span class='absolute weight-medium text-[0.6vw] uppercase right-1.5 text-[var(--font-600)]'>
                            {' '}
                            OFF{' '}
                          </span>
                        ) : (
                          <span class='absolute weight-medium text-[0.6vw] uppercase left-1.5 text-white'>
                            {' '}
                            ON{' '}
                          </span>
                        )}
                        <span class='w-4 h-4 right-[30px] absolute rounded-full transform transition-transform bg-[var(--BG-100)]' />
                      </label>
                    </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className='flex items-center justify-center my-20 font-default weight-semibold'>
            No Data
          </div>
        )}
      </div>
    </div>
  );
};

export default InviteMember;
