import React, { useCallback, useState } from 'react';

// assets
import { ICONS } from '../../../../assets/icons/index';
import { cn } from '../../../../helper/cn';

// API
import { CreateBrand } from '../../api/OnBoarding';

// hooks
import { useFileUploader } from '../../../../hooks/useFileUploader';

// helper
import { validateURL } from '../../../../helper/checkUrls';

// redux
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../test/jest-redux-hooks';

// components
import Loader from '../../../../components/commonComponents/Loader/Index';
import Select from '../../../../components/commonComponents/select';

const category = [
  {
    id: 1,
    label: 'Health care',
    value: 'Health care',
  },
  {
    id: 2,
    label: 'Retail Industry',
    value: 'Retail Industry',
  },
  {
    id: 3,
    label: 'E-Commerce',
    value: 'E-Commerce',
  },
  // {
  //   id: 4,
  //   label: 'Digital Agencies',
  //   value: 'Digital Agencies',
  // },
  {
    id: 5,
    label: 'Education',
    value: 'Education',
  },
  // {
  //   id: 6,
  //   label: 'FMCG',
  //   value: 'FMCG',
  // },
  // {
  //   id: 7,
  //   label: 'Travel',
  //   value: 'Travel',
  // },
  {
    id: 8,
    label: 'Other',
    value: 'Other',
  },
];

const countryList = [
  {
    label: 'India',
    value: '+91',
    shortName: 'IN',
  },
  // {
  //   label: 'United States',
  //   value: '+1',
  //   shortName: 'US',
  // },
  // {
  //   label: 'Japan',
  //   value: '+81',
  //   shortName: 'JP',
  // },
  // {
  //   label: 'Brazil',
  //   value: '+55',
  //   shortName: 'BR',
  // },
  // {
  //   label: 'Russia',
  //   value: '+7',
  //   shortName: 'RU',
  // },
  // {
  //   label: 'Canada',
  //   value: '+1',
  //   shortName: 'CA',
  // },
  // {
  //   label: 'Israel',
  //   value: '+972',
  //   shortName: 'IL',
  // },
];

const teamMembers = [
  {
    label: 'My Self',
    value: 'My Self',
  },
  {
    label: '1-10',
    value: '1-10',
  },
  {
    label: '11-25',
    value: '11-25',
  },
  {
    label: '26-50',
    value: '26-50',
  },
  {
    label: '50+',
    value: '50+',
  },
];

const knowAboutList = [
  {
    label: 'Facebooks',
    value: 'Facebooks',
  },
  {
    label: 'Instagram',
    value: 'Instagram',
  },
  {
    label: 'Linked in',
    value: 'Linked in',
  },
  {
    label: 'Website',
    value: 'Website',
  },
  {
    label: 'Friend refer',
    value: 'Friend refer',
  },
];

const categoryTypes = {
  'Health care': 'Health, Wellness & Fitness',
  'Retail Industry': 'Retail',
  'E-Commerce': 'Consumer Services',
  'Digital Agencies': '',
  Education: 'Education',
  FMCG: '',
  Travel: '',
  Other: 'Other',
};

export default function BrandCreation({
  data,
  onChange,
  fetchCurrentUserDetails,
  isLoading,
  setIsLoading,
}) {
  const { currentUser, currentAccount } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const [country, setCountry] = useState(null);
  const [changed, setChanged] = useState({
    url: false,
  });

  const {
    uploading,
    setUploading,
    selectedFile,
    selectedFileFormat,
    setSelectedFile,
    responseFile,
    uploadFiles,
    error,
    setError,
    clearFiles,
  } = useFileUploader();

  // styles
  const card = 'w-full flex flex-col gap-3 mt-4';
  const label = 'text-base weight-semibold leading-6 text-[#2D3036]';
  const inputContainer =
    'w-full h-12 flex items-center gap-2 py-2.5 px-3 border border-[#D1D3D8] rounded-lg';
  const input = 'w-full text-sm weight-medium';

  const validation = useCallback(() => {
    return (
      data?.name &&
      data?.website &&
      validateURL(data?.website) &&
      data?.category &&
      !error
    );
  }, [data, error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
      setIsLoading(true);
      const datas = {
        acc_id: currentAccount
          ? currentAccount?.account_id
          : currentUser?.account?.id,
        brand_name: data?.name,
        website_url: data?.website,
        brand_buss_vertical: categoryTypes[data?.category],
        country_alpha_code: data?.location,
        total_members: data?.totalMembers,
        referral_source: data?.knowAbout,
      };
      if (data?.image) datas['brand_img_url'] = data?.image;
      const res = await CreateBrand(datas);
      if (res?.status === 200) {
        setIsLoading(false);
        fetchCurrentUserDetails();
        // setTimeout(() => {
        //   onChange('update_step', null, 'step', 2);
        // }, [1000]);
        // const currentUserData = await currentUserDetails();

        // if (currentUserData?.status === 200) {
        //   dispatch(updateCurrentUser(currentUserData?.data));
        //   setTimeout(() => {
        //     onChange('update_step', null, 'step', 2);
        //   }, [1000]);
        // }
      } else {
        setIsLoading(false);
      }
    }
  };


  return (
    <div className='w-full flex flex-col items-center'>
      <div
        className={cn(
          'w-full max-w-[570px] flex items-center justify-between px-4 py-3 mt-6 bg-slate-50 rounded-[10px] border',
          error ? 'border-red-400 animate-shake' : 'border-[var(--border-50)]'
        )}
      >
        <div className='flex-row align-center'>
          <div className='rounded-[10px] size-12 bg-slate-100'>
            {!responseFile && (
              <div className='p-[14px]'>
                <img
                  src={ICONS.uploadStep}
                  alt='UploadStep'
                  className='upload-image-before'
                />
              </div>
            )}

            {selectedFile && (
              <div className='relative'>
                <img
                  src={responseFile}
                  alt='SelectedImage'
                  className='uploadStep-image'
                />
                <img
                  className='absolute pointer -top-2 -right-2 size-4'
                  src={ICONS.Exit}
                  alt='popup'
                  onClick={() => {
                    clearFiles();
                    onChange('update', 'account', 'image', null);
                  }}
                />
              </div>
            )}
          </div>
          <div className='upload-content'>
            <p className='account-image'>Brand Image</p>
            <p className='recommended-file'>
              {selectedFile ? (
                <p> {selectedFile?.name}</p>
              ) : (
                <p>Recommended file 320*320 px, less than 1MB.</p>
              )}
            </p>
          </div>
        </div>

        <div>
          <button className=''>
            <input
              type='file'
              onChange={async (e) => {
                const res = await uploadFiles(e, 'IMAGE', 1);
                if (res) {
                  onChange('update', 'brand', 'image', res);
                }
              }}
              accept='.jpg, .jpeg, .png'
              id='uploadImg'
              hidden
            />
            <label
              for='uploadImg'
              className='w-full flex-center cursor-pointer'
            >
              <div
                className={cn(
                  'min-w-[100px] text-sm weight-semibold text-[var(--background)] bg-[var(--primary)] flex items-center justify-center rounded-md px-4 py-1.5 tracking-wide'
                )}
              >
                {uploading ? (
                  <Loader
                    Width='1vw'
                    Height='1vw'
                    loaderBg='3px solid white'
                    loaderColor='3px solid var(--primary)'
                  />
                ) : (
                  <p>Upload</p>
                )}
              </div>
            </label>
          </button>
        </div>
      </div>
      {error && (
        <div className='max-w-[570px] w-full text-left text-xs mt-1 px-1 text-red-400'>
          File Should be less than 1MB.
        </div>
      )}

      <form className='w-full max-w-[570px]' onSubmit={handleSubmit}>
        <div className={cn(card)}>
          <label htmlFor='name' className={cn(label)}>
            Brand Name<sup className='text-red-500'>*</sup>
          </label>
          <div className={cn(inputContainer)}>
            <input
              name='name'
              id='name'
              type='text'
              placeholder='Ex:- Nike'
              className={cn(input)}
              value={data?.name}
              onChange={(e) => {
                const { name, value } = e.target;
                onChange('update', 'brand', name, value);
              }}
            />
          </div>
        </div>
        <div className={cn(card)}>
          <label htmlFor='website' className={cn(label)}>
            Website<sup className='text-red-500'>*</sup>
          </label>
          <div
            className={cn(
              inputContainer,
              changed?.url && !validateURL(data?.website) && 'border-red-500'
            )}
          >
            <input
              name='website'
              id='website'
              type='text'
              placeholder='Enter a valid URL'
              className={cn(input)}
              value={data?.website}
              onChange={(e) => {
                const { name, value } = e.target;
                onChange('update', 'brand', name, value);
                if (!changed?.url)
                  setChanged((prev) => {
                    return { ...prev, url: true };
                  });
              }}
            />
          </div>
        </div>

        <div className={cn(card)}>
          <label htmlFor='category' className={cn(label)}>
            Category<sup className='text-red-500'>*</sup>
          </label>
          <Select
            value={data?.category}
            options={category}
            onChange={({ value }) => {
              if (value) onChange('update', 'brand', 'category', value);
            }}
            placeholder={'Select category'}
            rootClassName='w-full h-12 px-3 py-2.5'
            position='top'
          />
        </div>
        <div className={cn(card)}>
          <label htmlFor='location' className={cn(label)}>
            Location
          </label>
          <Select
            value={country}
            options={countryList}
            onChange={(val) => {
              if (val) {
                onChange('update', 'brand', 'location', val?.shortName);
                setCountry(val);
              }
            }}
            placeholder={'Enter location'}
            rootClassName='w-full h-12 px-3 py-2.5'
            position='top'
          />
        </div>
        <div className={cn(card)}>
          <label htmlFor='totalMembers' className={cn(label)}>
            How many members are there in your team?
          </label>
          <Select
            value={data?.totalMembers}
            options={teamMembers}
            onChange={({ value }) => {
              if (value) onChange('update', 'brand', 'totalMembers', value);
            }}
            placeholder={'-- Select option --'}
            rootClassName='w-full h-12 px-3 py-2.5'
            position='top'
          />
        </div>
        <div className={cn(card)}>
          <label htmlFor='knowAbout' className={cn(label)}>
            How did you know about us?
          </label>
          <Select
            value={data?.knowAbout}
            options={knowAboutList}
            onChange={({ value }) => {
              if (value) onChange('update', 'brand', 'knowAbout', value);
            }}
            placeholder={'-- Select option --'}
            rootClassName='w-full h-12 px-3 py-2.5'
            position='top'
          />
        </div>
        <div className='flex justify-center mt-6'>
          <button
            type='submit'
            className={cn(
              'w-44 h-12 rounded-[10px] px-4 py-2 text-base weight-semibold flex items-center justify-center',
              validation()
                ? isLoading
                  ? 'cursor-not-allowed bg-[var(--primary)] text-[var(--white)]'
                  : 'bg-[var(--primary)] text-[var(--white)] cursor-pointer'
                : 'bg-[var(--BG-100)] text-[var(--font-600)] cursor-not-allowed'
            )}
          >
            {isLoading ? (
              <div className='size-[2vw] flex items-center justify-center'>
                <Loader
                  Width='20px'
                  Height='20px'
                  loaderBg='3px solid white'
                  loaderColor='3px solid var(--primary)'
                />
              </div>
            ) : (
              'Create Brand'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
